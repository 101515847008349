import React from 'react';
import { Typography } from '@material-ui/core';

function SwiperSlideComponent({ title, subtitle, image, isMobile }) {
  return (
    <div
      style={{
        position: 'relative',
        textAlign: 'center',
        objectFit: 'cover',
      }}
    >
      <img
        style={{
          height: isMobile ? '300px' : '500px',
          width: '100%',
          filter: isMobile ? 'brightness(.45)' : 'brightness(.55)',
          objectPosition: "50% 50%"
        }}
        src={image}
      />
      <section
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#fff',
          filter: 'none',
        }}
      >
        <Typography
          variant="h2"
          style={{
            fontSize: isMobile ? '24px' : '55px',
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          style={{
            fontSize: isMobile ? '14px' : '20px',
            color: '#e0e0e0',
          }}
          className="font-light"
        >
          {subtitle}
        </Typography>
      </section>
    </div>
  );
}

export default SwiperSlideComponent;
