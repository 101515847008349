import {
  Button,
  SpeedDial,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { myTheme } from 'theme';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import { TranslateContext } from 'TranslateContext';

const PageNotFound = () => {
  const isMobile = useMediaQuery(myTheme.breakpoints.down('md'));
  const translateContext = React.useContext(TranslateContext);

  useEffect(() => {
    translateContext.language === 'en' ? document.title = "Page Not Found" : document.title = "Página No Encontrada" 
  }, [translateContext.language]);

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: 'fixed', bottom: 16, left: 16 }}
        icon={translateContext.language === 'en' ? 'ES' : 'EN'}
        onClick={
          translateContext.language === 'en'
            ? () => translateContext.setLanguage('es')
            : () => translateContext.setLanguage('en')
        }
      ></SpeedDial>
      <Stack
        direction="column"
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ marginTop: '30px' }}
      >
        <FmdBadIcon
          sx={{ fontSize: isMobile ? '68px' : '88px' }}
          color={'primary'}
        />
        <Typography
          variant="h5"
          component="div"
          sx={{ fontSize: isMobile ? '34px' : '48px', textAlign: 'center' }}
          className="font-bold"
        >
          404 Page Not Found!
        </Typography>

        <Typography
          sx={{
            color: '#8a8a8a',
            fontSize: isMobile ? '18px' : '18px',
            textAlign: 'center',
          }}
          className="font-light"
        >
          The page you were looking for could not be found.
        </Typography>
        <Button
          variant="outlined"
          href="/"
          sx={{
            marginTop: '30px',
          }}
        >
          Go Back to Home
        </Button>
      </Stack>
    </>
  );
};

export default PageNotFound;
