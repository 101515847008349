import {
  Box,
  Button,
  SpeedDial,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { TranslateContext } from 'TranslateContext';
import TeacherCard from 'components/TeacherCard';
import React, { useEffect } from 'react';
import { myTheme } from 'theme';
import teachers from '../images/teachers.png';

const About = () => {
  const isMobile = useMediaQuery(myTheme.breakpoints.down('md'));
  const translateContext = React.useContext(TranslateContext);

  useEffect(() => {
    translateContext.language === 'en'
      ? (document.title = 'About / Class Offerings')
      : (document.title = 'Sobre / Ofertas de Clase');

  }, [translateContext.language]);

  return (
    <Box sx={{ flexGrow: 1 }} alignItems={'center'} textAlign={'center'}>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: 'fixed', bottom: 16, left: 16 }}
        icon={translateContext.language === 'en' ? 'ES' : 'EN'}
        onClick={
          translateContext.language === 'en'
            ? () => translateContext.setLanguage('es')
            : () => translateContext.setLanguage('en')
        }
      ></SpeedDial>
      <Typography
        variant="h2"
        sx={{
          fontSize: isMobile ? '50px' : '65px',
          textAlign: 'center',
          marginTop: isMobile ? '10px' : '45px',
        }}
        className="font-light"
      >
        {translateContext.language === 'en'
            ? "About Us"
            : "Quiénes Somos"}
      </Typography>
      <Box
        sx={{
          width: '25%',
          height: '5px',
          display: 'block',
          marginX: 'auto',
          marginTop: '20px',
          marginBottom: '40px',
          backgroundColor: '#1769aa',
        }}
      />
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={1}
        sx={{ margin: '10px' }}
        alignItems={'center'}
      >
        <Box sx={{ width: '100%', textAlign: 'center' }} alignItems={'center'}>
          <img
            width={isMobile ? 300 : 600}
            src={teachers}
            style={{borderRadius: 5}}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{
              color: '#8a8a8a',
              fontSize: isMobile ? '18px' : '22px',
              textAlign: 'center',
              margin: '40px',
            }}
            className="font-light"
          >
            {translateContext.language === 'en'
              ? 'Fluent Futures Foundation came to be as a shared interest through the collective points of views of our board members. Through conversation with our community members, it became clear to us that there was a need being unmet within the Hispanic community. Living in the midwest, where cultural diversity is wide and yet lingusitic diveisty is bleek, we recongizenized the need for free and accessible English classes. Partnering with local community members, churches, and organizations, this foundation seeks to become not only a powerful tool but also a resource for any and all members of any community.'
              : 'Fluent Futures Foudnation surgió como un interés compartido a través de los puntos de vista colectivos de nuestros miembros de la junta directiva.A través de la conversación con los miembros de nuestra comunidad, nos quedó claro que había una necesidad insatisfecha dentro de la comunidad hispana. Viviendo en el medio oeste, donde la diversidad cultural es amplia, la diversidad lingüística es sombría, reconocimos la necesidad de clases de inglés gratis y accesibles. Con la ayuda de nuestero miembros de la comunidad local, iglesias y organizaciones, esta fundación busca convertirse no solo en una herramienta poderosa, sino también en un recurso para todos y cada uno de los miembros de cualquier comunidad.'}
          </Typography>
        </Box>
      </Stack>
      {/* <hr style={{ margin: '40px' }} /> */}
      {/* <Typography
        variant="h2"
        sx={{
          fontSize: isMobile ? '50px' : '35px',
          textAlign: 'center',
          marginY: isMobile ? '10px' : '45px',
        }}
        className="font-light"
      >
        Meet the Teachers
      </Typography>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={1}
        alignItems={'center'}
        sx={{ marginX: '10px' }}
      >
        <TeacherCard
          image={
            'https://cdn.create.vista.com/api/media/small/12767124/stock-photo-joyful-man-face'
          }
          name={'Jun Kim'}
          subtitle={
            'sollicitudin. Pellentesque nec nam aliquam sem et tortor consequat id Pretium nibh ipsum consequat nisl vel. Lobortis scelerisque fermentum dui faucibus in ornare. Malesuada fames ac turpis egestas sed tempus'
          }
          isMobile={isMobile}
        />
        <TeacherCard
          image={
            'https://t4.ftcdn.net/jpg/02/46/34/43/360_F_246344306_E4KQf8mvvWsCAwrSMEEY6GUOa22TjiUn.jpg'
          }
          name={'Jane Doe'}
          subtitle={
            'sollicitudin. Pellentesque nec nam aliquam sem et tortor consequat id Pretium nibh ipsum consequat nisl vel. Lobortis scelerisque fermentum dui faucibus in ornare. Malesuada fames ac turpis egestas sed tempus'
          }
          isMobile={isMobile}
        />
        <TeacherCard
          image={
            'https://st.depositphotos.com/2931363/3703/i/950/depositphotos_37034497-stock-photo-young-black-man-smiling-at.jpg'
          }
          name={'John Smith'}
          subtitle={
            'sollicitudin. Pellentesque nec nam aliquam sem et tortor consequat id Pretium nibh ipsum consequat nisl vel. Lobortis scelerisque fermentum dui faucibus in ornare. Malesuada fames ac turpis egestas sed tempus'
          }
          isMobile={isMobile}
        />
      </Stack> */}
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={3}
        sx={{ margin: '20px' }}
      >
        <Stack direction={'column'} alignItems={'center'} width={'100%'}>
          <Box
            className="circle"
            textAlign={'center'}
            display={'flex'}
            alignItems="center"
            justifyContent={'center'}
            boxShadow={3}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{ fontSize: isMobile ? '14px' : '25px', textAlign: 'center' }}
              className="font-bold"
            >
              1
            </Typography>
          </Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontSize: isMobile ? '14px' : '25px', textAlign: 'center' }}
            className="font-bold"
          >
            {translateContext.language === 'en'
              ? 'Class Offering - Beginner'
              : 'Clases Disponibles - Principiantes'}
          </Typography>
          <Typography
            sx={{
              color: '#8a8a8a',
              fontSize: isMobile ? '12px' : '18px',
              textAlign: 'center',
            }}
            className="font-light"
          >
            {translateContext.language === 'en'
              ? 'The beginner classes that we offer is a 10 week course that will go over  basic mechanics of reading, writing, and speaking in English.'
              : 'Las clases para principiantes que ofrecemos son un curso de 10 semanas que repasará la mecánica básica de lectura, escritura y expresión oral en inglés.'}
          </Typography>
        </Stack>
        <Stack direction={'column'} alignItems={'center'} width={'100%'}>
          <Box
            className="circle"
            textAlign={'center'}
            display={'flex'}
            alignItems="center"
            justifyContent={'center'}
            boxShadow={3}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{ fontSize: isMobile ? '14px' : '25px', textAlign: 'center' }}
              className="font-bold"
            >
              2
            </Typography>
          </Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontSize: isMobile ? '14px' : '25px', textAlign: 'center' }}
            className="font-bold"
          >
            {translateContext.language === 'en'
              ? 'Class Offering - Intermediate'
              : 'Clases Disponibles - Intermedio'}
          </Typography>
          <Typography
            sx={{
              color: '#8a8a8a',
              fontSize: isMobile ? '12px' : '18px',
              textAlign: 'center',
            }}
            className="font-light"
          >
            {translateContext.language === 'en'
              ? 'The intermediate classes is a 10 week course that is geared towards those who posses a strong speaking or writing foundation but wish to advance the skills to a higher level.'
              : 'Las clases intermedias es un curso de 10 semanas que está dirigido a aquellos que poseen una base sólida para hablar o escribir Ingles, pero desean avanzar en las habilidades a un nivel superior.'}
          </Typography>
        </Stack>
        <Stack direction={'column'} alignItems={'center'} width={'100%'}>
          <Box
            className="circle"
            textAlign={'center'}
            display={'flex'}
            alignItems="center"
            justifyContent={'center'}
            boxShadow={3}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{ fontSize: isMobile ? '14px' : '25px', textAlign: 'center' }}
              className="font-bold"
            >
              3
            </Typography>
          </Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ fontSize: isMobile ? '14px' : '25px', textAlign: 'center' }}
            className="font-bold"
          >
            {translateContext.language === 'en'
              ? 'Class Offering - Advanced'
              : 'Clases Disponibles - Avanzados'}
          </Typography>
          <Typography
            sx={{
              color: '#8a8a8a',
              fontSize: isMobile ? '12px' : '18px',
              textAlign: 'center',
            }}
            className="font-light"
          >
            {translateContext.language === 'en'
              ? 'The advanced course is a 10 week course where well assist in furthering your advanced skills in English and start looking into avenues beyond just reading and writing to help with every day life.'
              : 'El curso avanzado es un curso de 10 semanas en el que le ayudaremos a mejorar sus habilidades avanzadas en inglés y comenzaremos a buscar vías más allá de la lectura y la escritura para ayudarlo con la vida diario'}
          </Typography>
        </Stack>
      </Stack>
      {isMobile ? <hr style={{ margin: '40px' }} /> : <></>}
      <Typography
        sx={{
          color: '#8a8a8a',
          fontSize: isMobile ? '18px' : '22px',
          textAlign: 'center',
          margin: '40px',
        }}
        className="font-light"
      >
        {translateContext.language === 'en'
          ? 'Please contact us to get information about open availabiltiy for classes. Please note that there will be an entry level exam to determine the best class placement.'
          : 'El curso avanzado es un curso de 10 semanas en el que le ayudaremos a mejorar sus habilidades avanzadas en inglés y comenzaremos a buscar vías más allá de la lectura y la escritura para ayudarlo con la vida diario.'}
      </Typography>
      <Button
        variant="outlined"
        sx={{
          ':hover': {
            bgcolor: 'transparent',
          },
        }}
        href="/contact"
      >
        {translateContext.language === 'en' ? "Enroll Now" : "Inscribirse Ahora"}
      </Button>
    </Box>
  );
};

export default About;
