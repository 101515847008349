import { CardContent, Typography, Box } from '@mui/material';
import Card from '@mui/material/Card';
import React from 'react';

function ClassCard({ icon, title, subtitle, isMobile }) {
  return (
    <Card square={true} elevation={0} sx={{width: "100%"}}>
      <CardContent >
        {icon}
        <Typography variant="h5" component="div" sx={{fontSize: isMobile ? '14px' : '20px', textAlign: "center"}} className="font-bold">
          {title}
        </Typography>
        <Typography sx={{ color: '#8a8a8a', fontSize: isMobile ? '18px' : '25px', textAlign: "center" }} className="font-light">
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default ClassCard;
