import { Box, Button, SpeedDial, Stack, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import classroom from '../images/classroom.jpg';
import explaining from '../images/explaining.jpg';
 

// Import Swiper styles
import 'swiper/css';
import { myTheme } from 'theme';
import SwiperSlideComponent from 'components/SwiperSlideComponent';
import ClassCard from 'components/ClassCard';
import { TranslateContext } from 'TranslateContext';
import { Link } from 'react-router-dom';

const Home = () => {
  const isMobile = useMediaQuery(myTheme.breakpoints.down('md'));
  const translateContext = React.useContext(TranslateContext);

  useEffect(() => {
    document.title = "Fluent Futures Foundation";
  }, []);

  return (
    <>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: 'fixed', bottom: 16, left: 16 }}
        icon={translateContext.language === 'en' ? 'ES' : 'EN'}
        onClick={
          translateContext.language === 'en'
            ? () => translateContext.setLanguage('es')
            : () => translateContext.setLanguage('en')
        }
      ></SpeedDial>

      <Box sx={{ flexGrow: 1 }} textAlign={'center'}>
        <Swiper
          slidesPerView={1}
          spaceBetween={0}
          loop={true}
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <SwiperSlideComponent
              title={
                translateContext.language === 'en'
                  ? 'Our Mission'
                  : 'Nuestra misión'
              }
              subtitle={
                translateContext.language === 'en'
                  ? 'Through linguistic and career based courses, Fluent Futures Foundation seeks to bridge the gap that individuals may face when navigating through everyday life'
                  : 'Con lingüístico y coursos de carrera , Fluent Futures Foundation busca a ayudar con las dificultades que uno vea cuando nevigando el la vida diaria.'
              }
              image={classroom}
              isMobile={isMobile}
            />
          </SwiperSlide>

          <SwiperSlide>
            <SwiperSlideComponent
              title={
                translateContext.language === 'en'
                  ? 'Our Vision'
                  : 'Nuestro Visión'
              }
              subtitle={
                translateContext.language === 'en'
                  ? 'We seek to uplift undeserved members of our community by providing skills and services that make achieving their dreams attainable'
                  : 'Buscamos elevar a los miembros desatendidos de nuestra comunidad al proporcionar habilidades y servicios que hacen que el logro de sus sueños sea alcanzable.'
              }
              image={explaining}
              isMobile={isMobile}
            />
          </SwiperSlide>
        </Swiper>
        <Box sx={{ margin: '10px 10px 25px' }}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={2}
            sx={{ marginY: '10px' }}
          >
            <ClassCard
              icon={
                <DirectionsWalkIcon
                  fontSize="large"
                  sx={{ color: '#383838' }}
                />
              }
              title={
                translateContext.language === 'en'
                  ? 'Beginner'
                  : 'Principiantes'
              }
              subtitle={
                translateContext.language === 'en'
                  ? 'This level is meant for individuals with little to no prior knowledge in the English language. Students will learn the basic mechanics of reading, writing, and speaking in English. Upon completion, students are recommended to pursue the intermediate level for further education.'
                  : 'Este nivel está destinado a individuos con poco conocimientos previos en el idioma Inglés. Los estudiantes aprenderán la mecánica básica de leer, escribir y hablar en Inglés. Al finalizar se recomienda a los estudiantes que adquieran el nivel intermedio para la educación superior.'
              }
              isMobile={isMobile}
            />
            {isMobile ? <hr style={{ margin: '40px' }} /> : <></>}
            <ClassCard
              icon={
                <DirectionsBikeIcon
                  fontSize="large"
                  sx={{ color: '#383838' }}
                />
              }
              title={
                translateContext.language === 'en'
                  ? 'Intermediate'
                  : 'Intermedio'
              }
              subtitle={
                translateContext.language === 'en'
                  ? 'This level is intended for individuals whom possess prior knowledge and a strong foundation of both written and spoken English. Entry level exams will be administered to determine placement in this course.'
                  : 'El curso intermedio está destinado a individuos a quienes poseen conocimientos y una base sólida de Inglés, hablar y escribir. Los exámenes de nivel de entrada serán administrados para determinar la colocación en este curso.'
              }
              isMobile={isMobile}
            />
            {isMobile ? <hr style={{ margin: '40px' }} /> : <></>}
            <ClassCard
              icon={
                <AirportShuttleIcon
                  fontSize="large"
                  sx={{ color: '#383838' }}
                />
              }
              title={
                translateContext.language === 'en' ? 'Advanced' : 'Avanzados'
              }
              subtitle={
                translateContext.language === 'en'
                  ? 'This level is created for individuals who comfortably speak, read, and write in English. This course will assist in honing their skills and futhering their language abilities.'
                  : 'El curso de nivel avanzado está creado para personas que hablan cómodamente leen y escriben en Inglés. Este curso ayudará a perfeccionar y mejorar sus habilidades lingüísticas.'
              }
              isMobile={isMobile}
            />
          </Stack>
          <Link to="/about">
            <Button
              variant="outlined"
              sx={{
                ':hover': {
                  bgcolor: 'transparent',
                },
              }}
            >
              {translateContext.language === 'en'
                ? 'Learn More'
                : 'Más Información'}
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default Home;
