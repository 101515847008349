import { CardContent, CardMedia, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import React from 'react';

function TeacherCard({ image, name, subtitle, isMobile }) {
  return (
    <Card  elevation={2} sx={{ width: '100%' }}>
      <CardMedia
        sx={{ height: "250px" }}
        image={image}
        title={name}
      />
      <CardContent>
        <Typography
          variant="h5"
          component="div"
          sx={{ fontSize: isMobile ? '14px' : '25px', textAlign: 'center' }}
          className="font-bold"
        >
          {name}
        </Typography>
        <Typography
          sx={{
            color: '#8a8a8a',
            fontSize: isMobile ? '12px' : '18px',
            textAlign: 'center',
          }}
          className="font-light"
        >
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default TeacherCard;
