import React from 'react';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { TranslateContext } from 'TranslateContext';
import { Link } from 'react-router-dom';

function Menu() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const translateContext = React.useContext(TranslateContext);

  return (
    <>
      <Drawer
        open={openDrawer}
        anchor={'right'}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <Link to="/">
              <Button
                color="inherit"
                sx={{ textDecoration: 'none', color: 'black' }}
              >
                {translateContext.language === 'en'
                  ? 'Home'
                  : 'La Página Principal'}
              </Button>
            </Link>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <Link to="/about">
              <Button
                color="inherit"
                sx={{ textDecoration: 'none', color: 'black' }}
              >
                {translateContext.language === 'en' ? 'About / Class Offerrings' : 'Sobre / Ofertas de Clase'}
              </Button>
            </Link>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <Link to="/contact">
              <Button color="inherit" sx={{ textDecoration: 'none', color: 'black' }}>
                {translateContext.language === 'en' ? 'Contact' : 'Contáctanos'}
              </Button>
            </Link>
          </ListItem>
          {/* <ListItem onClick={() => setOpenDrawer(false)}>
            <Link to="/donate">
              <Button variant="outlined" sx={{ textDecoration: 'none'}}>
                {translateContext.language === 'en' ? 'Donate' : 'Donar'}
              </Button>
            </Link>
          </ListItem> */}
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  );
}

export default Menu;
