import { createTheme } from "@mui/material";

export const myTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          ":hover": {
            textDecoration: "underline",
            backgroundColor: "transparent"
          },
        },
      },
    },
  },
});