import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from './Menu';
import { useMediaQuery } from '@mui/material';
import { myTheme } from '../theme';
import logo from '../images/logo.jpg';
import { TranslateContext } from 'TranslateContext';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const isMobile = useMediaQuery(myTheme.breakpoints.down('md'));
  const translateContext = React.useContext(TranslateContext);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          <Box sx={{ flexGrow: 1, textDecoration: 'none', color: 'black' }}>
            <Link to="/">
              <Typography variant="h6" component="div" className="font-light">
                <img src={logo} alt="Logo" width={'150px'}></img>
              </Typography>
            </Link>
          </Box>

          {isMobile ? (
            <Menu />
          ) : (
            <>
              <Link to="/about">
                <Button
                  color="inherit"
                  sx={{ textDecoration: 'none', color: 'black' }}
                >
                  {translateContext.language === 'en' ? 'About / Class Offerrings' : 'Sobre / Ofertas de Clase'}
                </Button>
              </Link>
              <Link to="/contact">
                <Button
                  color="inherit"
                  sx={{ textDecoration: 'none', color: 'black' }}
                >
                  {translateContext.language === 'en'
                    ? 'Contact'
                    : 'Contáctanos'}
                </Button>
              </Link>
              {/* <Link to="/donate">
                <Button
                  variant="outlined"
                  sx={{ textDecoration: 'none' }}
                >
                  {translateContext.language === 'en' ? 'Donate' : 'Donar'}
                </Button>
              </Link> */}
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
