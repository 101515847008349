import React, { useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import { ThemeProvider } from '@emotion/react';
import { myTheme } from './theme';
import Footer from 'components/Footer';
import Donate from 'pages/Donate';
import PageNotFound from 'pages/PageNotFound';

import { TranslateContext } from './TranslateContext';

function App() {
  const [language, setLanguage] = useState('en');

  return (
    <TranslateContext.Provider value={{ language, setLanguage }}>
      <Router>
        <ThemeProvider theme={myTheme}>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route path="/donate" element={<Donate />} /> */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </ThemeProvider>
      </Router>
    </TranslateContext.Provider>
  );
}

export default App;
