const { Box, Stack, IconButton, Typography } = require("@mui/material");
const React = require("react");
import FacebookIcon from '@mui/icons-material/Facebook';

function Footer(){
    return(
        <Box
        sx={{ marginTop: '85px' }}
        alignItems="center"
        display="flex"
        justifyContent="center"
      >
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <IconButton href='https://www.facebook.com/profile.php?id=100095099037942&mibextid=LQQJ4d'>
            <FacebookIcon />
          </IconButton>
          <Typography>contact@fluentfuturesfoundation.org</Typography>
        </Stack>
      </Box>
    );
}

export default Footer;