import {
  Box,
  Button,
  SpeedDial,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { myTheme } from 'theme';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ReCAPTCHA from 'react-google-recaptcha';
import { TranslateContext } from 'TranslateContext';

const Contact = () => {
  const isMobile = useMediaQuery(myTheme.breakpoints.down('md'));
  const captchaKey = process.env.REACT_APP_SITE_RECAPTCHA_KEY || 'examplekey';
  const translateContext = React.useContext(TranslateContext);

  useEffect(() => {
    translateContext.language === 'en'
      ? (document.title = 'Contact Us')
      : (document.title = 'Contáctanos');
  }, [translateContext.language]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        sx={{ position: 'fixed', bottom: 16, left: 16 }}
        icon={translateContext.language === 'en' ? 'ES' : 'EN'}
        onClick={
          translateContext.language === 'en'
            ? () => translateContext.setLanguage('es')
            : () => translateContext.setLanguage('en')
        }
      ></SpeedDial>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        spacing={isMobile ? 5 : 2}
        sx={{ marginY: isMobile ? '20px' : '80px' }}
      >
        <div style={{ width: '100%' }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: isMobile ? '50px' : '65px',
              textAlign: 'center',
            }}
            className="font-light"
          >
            {translateContext.language === 'en' ? 'Contact Us' : 'Contactarnos'}
          </Typography>
          <Stack
            direction={'column'}
            sx={{ width: '100%', marginTop: '40px' }}
            alignItems={'center'}
            spacing={2}
          >
            <Typography
              sx={{
                color: '#8a8a8a',
                fontSize: isMobile ? '18px' : '22px',
                textAlign: 'center',
              }}
              className="font-light"
            >
              contact@fluentfuturesfoundation.org
            </Typography>
            <Typography
              sx={{
                color: '#8a8a8a',
                fontSize: isMobile ? '18px' : '22px',
                textAlign: 'center',
              }}
              className="font-light"
            >
              (260)739-8443
            </Typography>
            <Typography
              sx={{
                color: '#8a8a8a',
                fontSize: isMobile ? '18px' : '22px',
                textAlign: 'center',
              }}
              className="font-light"
            >
              6011 Hessen Cassel Rd <br />
              Fort Wayne, IN 46816
            </Typography>
          </Stack>
        </div>
        <form
          style={{ width: '100%' }}
          name="contact"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <Stack
            direction={'column'}
            sx={{ width: '100%' }}
            alignItems={'center'}
            spacing={3}
          >
            <Typography
              variant="h1"
              style={{
                fontSize: isMobile ? '24px' : '30px',
                textAlign: 'center',
              }}
              className="font-light"
            >
              {translateContext.language === 'en'
                ? 'Send a message'
                : 'Enviarnos un mensaje'}
              <ChatBubbleIcon color="primary" fontSize="large" />
            </Typography>
            <input type="hidden" name="form-name" value="contact" />
            <TextField
              label={
                translateContext.language === 'en'
                  ? 'Full Name'
                  : 'Nombre Completo'
              }
              size="small"
              required
              sx={{ width: '80%' }}
              name="name"
            />
            <TextField
              label={
                translateContext.language === 'en'
                  ? 'Email'
                  : 'Correo Electrónico'
              }
              size="small"
              type="email"
              required
              sx={{ width: '80%' }}
              name="email"
            />
            <p className="hidden">
              <label>
                Dont fill this out if youre human: <input name="bot-field" />
              </label>
            </p>
            <TextField
              label={
                translateContext.language === 'en' ? 'Message' : 'Su Mensaje'
              }
              multiline
              rows={4}
              required
              sx={{ width: '80%' }}
              name="message"
            />
            <ReCAPTCHA
              sitekey={captchaKey}
              key={translateContext.language === 'en' ? 'en' : 'es'}
              hl={translateContext.language === 'en' ? 'en' : 'es'}
            />
            <Button
              variant="contained"
              type="submit"
              sx={{
                ':hover': {
                  bgcolor: '#1764b0',
                },
                width: '80%',
              }}
            >
              {translateContext.language === 'en'
                ? 'Send Message'
                : 'Enviar Mensaje'}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};

export default Contact;
